<template>
  <div class="section-blog-posts-post">
    <div class="section-blog-posts-post-wrap">
      <router-link :to="`/blog/post?id=${post.id}#post`" class="section-blog-posts-post-wrap-link">
        <div class="section-blog-posts-post-picture">
          <div class="wrap" :style="{ '--postImage': 'url(' + urlCloudFront(post.coverLink) + ')' }"></div>
          <div v-if="post.hasVideos">
            <div class="wrapper-btn">
              <img class="btn-play" src="https://new-web-site-bucket.s3.amazonaws.com/website/blog/iconPlay.svg" alt="Icon para reproducir contenido" />
            </div>
            <div class="gradient"></div>
          </div>
        </div>

        <div class="section-blog-posts-post-content">
          <div class="title">{{ post.title }}</div>
          <div class="date-info">
            <span class="date">{{ dateFormat(post.date) }}</span>
          </div>
          <div class="text">{{ getShort(removeHTMLEntities(post.describe), 150) }}</div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import global from "../../assets/js/global";
export default {
  props: {
    post: Object,
  },
  methods: {
    urlCloudFront(link) {
      return this.$global.newUrl(link);
    },
    dateFormat: (date) => {
      return global.dateLocaleFormat(date);
    },
    getShort: (text, limit) => {
      return global.getShort(text, limit);
    },
    removeHTMLEntities: (text) => {
      return global.removeHTMLEntities(text);
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/vars.scss";
$textColor: #5b5957;
.section-blog-posts-post {
  width: 100%;
  ul,
  ol {
    padding-left: 25px;
  }
  ul li {
    list-style-type: disc;
  }
  ol li {
    list-style-type: decimal;
  }
  &-wrap {
    margin: $mpadding/2;
    padding: $mpadding/2;
    box-shadow: $dshadow;
    border-radius: $mradius;
    height: calc(100% - #{$mpadding});
    position: relative;
    &-link {
      display: flex;
      justify-content: space-between;
    }
  }

  &-picture {
    width: 161px;
    height: 90px;
    position: relative;
    .gradient {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1;
      border-radius: 12px;
      background-image: linear-gradient(0deg, #272727 0%, rgba(49, 49, 49, 0.2) 31%, rgba(19, 19, 19, 0) 100%);
    }
    .wrap {
      height: 100%;
      border-radius: $mradius;
      background-image: var(--postImage);
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;
    }
    .wrapper-btn {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      position: absolute;
      right: 5px;
      bottom: 5px;
      z-index: 2;
      .btn-play {
        width: 80%;
        position: absolute;
        left: 55%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  &-content {
    text-align: left;
    padding-left: $mpadding;
    width: 190px;
    .title {
      font-family: $sec_font;
      color: $primary_color;
      font-size: 16px;
      padding: 0 0 $mpadding/4 0;
    }
    .date-info {
      padding: 0 0 $mpadding/4 0;
      font-size: 9px;
      font-weight: bold;
      color: black;
      .slash {
        margin: 0 2px;
      }
    }
    .text {
      font-size: 9px;
      color: black;
    }
  }
  @media (min-width: $tablet_width) {
    width: 33.33%;
    &-wrap {
      padding: $mpadding;
      &-link {
        display: block;
      }
    }
    &-picture {
      width: 100%;
      height: 185px;
      &-wrap {
        background-position: top center;
        background-repeat: no-repeat;
      }
      .wrapper-btn {
        width: 50px;
        height: 50px;
      }
    }
    &-content {
      width: 100%;
      padding-left: 0;
      .title {
        font-size: 19px;
        padding: $mpadding/2 0;
      }
      .date-info {
        padding: 0 0 $mpadding/2 0;
      }
      .text {
        font-size: 14px;
      }
    }
  }
}
</style>
